import React from 'react';

import NavBar from './Nav';

import { useAuth0 } from "@auth0/auth0-react";

function AccountPage({ logoutFunction }) {
  const { user } = useAuth0();

  function handleLogout() {


    logoutFunction();
  }

  return (

    <div>
      <NavBar />
      <h1>Account Info</h1>

      <div className="user-info-card">
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>{user.email}</p>
        <button className="logout red-button" onClick={handleLogout}>
          Log Out
        </button>
      </div>
    </div >
  );

}

export default AccountPage;