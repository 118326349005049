
import React, { useState } from "react";
import { Link } from 'react-router-dom';

export default function RecipeModal({ recipe, closeModalFunction, userPlans, addToPlan }) {

    const [showPlans, setShowPlans] = useState(false);

    const [loading, setLoad] = useState(false);

    const [moreInfo, setMoreInfo] = useState(false);

    function handleClose() {
        closeModalFunction();
    }

    function handleListClick() {
        setShowPlans(!showPlans);
        console.log(userPlans);
    }

    async function addClick(plan) {
        setLoad(true);
        if (await addToPlan(plan, recipe)) {
            setShowPlans(false);
        };
        setLoad(false);
    }

    function showMoreInfo() {
        setMoreInfo(!moreInfo);
    }

    //console.log(recipe);


    return (
        <div className="recipe-modal" >
            <div className="recipe-modal-content">
                {recipe.title === 'Loading Details ...' ? <h1>Loading Recipe...</h1> : <div>
                    <div className="m-recipe-options">
                        <button onClick={handleClose}>Close</button>
                        <button onClick={handleListClick}>Add to Map</button>
                        <button onClick={showMoreInfo}>Show {moreInfo ? 'Less' : 'More'} Info</button>
                        {showPlans ? <ul className="add-plan-list">
                            {
                                loading ?
                                    <p>Loading...</p>
                                    :
                                    userPlans.length === 0 ?
                                        <Link to="/maps"><li>You currently have no maps</li></Link>
                                        :
                                        userPlans.map((plan) => (
                                            <li key={plan.title} onClick={() => addClick(plan)}>{plan.title}</li>
                                        ))
                            }
                        </ul>
                            : null}
                    </div>

                    <h1>{recipe.title}</h1>
                    <p>Source : <a href={recipe.sourceUrl}>{recipe.sourceName}</a></p>
                    <img className="m-recipe-image" src={recipe.image} alt={recipe.title}></img>
                    <div className="m-recipe-summary" dangerouslySetInnerHTML={{ __html: recipe.summary }} />
                    {moreInfo ?
                        <div className='m-more-info-div'>
                            <h2>Ingredients :</h2>
                            <ol className="modal-ordered-list">{recipe.extendedIngredients.map((ingredient) => (
                                <li>{ingredient.original}</li>
                            ))}
                            </ol>
                            <h2>Instructions :</h2>
                            <ol className="modal-ordered-list">{recipe.analyzedInstructions[0].steps.map((instruction) => (
                                <li>{instruction.step}</li>
                            ))}
                            </ol>
                        </div>
                        : null}

                </div>}

            </div>
        </div>
    )
}