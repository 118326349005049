import React from 'react';
import NavBar from './Nav';
//import './css/App.css';

function HomePage() {
  return (
    <div className='container'>
      <NavBar />
      <h1>Welcome to Meal Map!</h1>
      <div className='home-grid'>
        <div className='home-grid-item'>
          <h2>Find</h2>

          <p>Browse MILLIONS of popular Recipes from all over the world thanks to the Spoonacular database</p>
        </div>
        <div className='home-grid-item'>
          <h2>Map</h2>
          <p>Add your favorites to a map to get detailed ingredient lists that simplify the preparation process</p>
        </div>
        <div className='home-grid-item'>
          <h2>Enjoy</h2>
          <p>After one trip to the grocery store, its time for the fun part! Cooking and eating!</p>
        </div>
      </div>
      {/* More components or HTML here */}
    </div>
  );
}


export default HomePage;