import React from "react";

/*
sample-result {
    id:1079930
    title:"Crock Pot Pasta Bolognese Sauce"
    image:"https://spoonacular.com/recipeImages/1079930-312x231.jpg"
}
*/
export default function SearchResults({ results, focusRecipeFunction }) {
    return (
        <div className="search-results-grid">
            {results.map((result, index) => (
                <div
                    key={result.id}
                    className="search-results-grid-item"
                    onClick={() => focusRecipeFunction(result.id)}
                >
                    <img src={result.image} alt={result.title} />
                    <h2>{result.title}</h2>
                </div>
            ))}
        </div>
    );
}
