import React from 'react';
import ReactDOM from 'react-dom/client';
import './components/css/index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-c4yab07358b4eyct.us.auth0.com"
      clientId="D96AZEq1uzzUPIDkR7rUZbm9IJlFChV2"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "meamaplbackend.uc.r.appspot.com",
        //scope: "read:current_user update:current_user_metadata read:users_app_metadata",
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);



