import React from "react";
import { useState } from "react";


export default function SearchFilters({ updateTypeFunction, updateAllergyFunction }) {
    const [typeVisible, setTypeVisible] = useState(false);
    const [selectedType, setSelectedType] = useState('');

    const [allergiesVisible, setAllergiesVisible] = useState(false);
    const [selectedAllergies, setSelectedAllergies] = useState(new Set());

    const types = ['any', 'main course', 'side dish', 'dessert', 'appetizer', 'salad', 'bread', 'breakfast', 'soup', 'beverage', 'sauce', 'drink'];
    const allergies = ['dairy', 'egg', 'gluten', 'peanut', 'sesame', 'seafood', 'shellfish', 'soy', 'sulfite', 'tree nut', 'wheat'];


    function handleTypeCheck(event) {
        const newSelectedType = event.target.value;
        if (!event.target.checked || newSelectedType === 'any') {
            setSelectedType('');
        } else {
            setSelectedType(newSelectedType);
        }

    };

    function handleTypeSubmit(event) {
        event.preventDefault();
        updateTypeFunction(selectedType);
        console.log(selectedType);
        // Now you have the selected types as an array. You can handle it here as you wish
    };

    function handleAllergyCheck(event) {
        const newSelectedAllergies = new Set(selectedAllergies);
        if (event.target.checked) {
            newSelectedAllergies.add(event.target.value);
        } else {
            newSelectedAllergies.delete(event.target.value);
        }
        setSelectedAllergies(newSelectedAllergies);
    };

    function handleAllergySubmit(event) {
        event.preventDefault();
        console.log(Array.from(selectedAllergies));
        updateAllergyFunction(Array.from(selectedAllergies));
        // Now you have the selected types as an array. You can handle it here as you wish
    };
    return (
        <div className="filter-side-bar">
            <div>
                <button className="collapse-trigger" onClick={() => setTypeVisible(!typeVisible)}>Types</button>
                <div className={`filter-contents ${typeVisible ? 'visible' : 'hidden'}`}>
                    <form onSubmit={handleTypeSubmit} className="filter-form">
                        {types.map((type, index) => (
                            <div key={index} className="filter-checkbox">
                                <label>
                                    <input type="radio" name="selectedType" value={type} onChange={handleTypeCheck} />
                                    {type}
                                </label>
                            </div>
                        ))}
                        <button type="submit" className="apply-button">Apply</button>
                    </form>

                </div>
            </div>
            <div>
                <button className="collapse-trigger" onClick={() => setAllergiesVisible(!allergiesVisible)}>Allergies</button>
                <div className={`filter-contents ${allergiesVisible ? 'visible' : 'hidden'}`}>
                    <form onSubmit={handleAllergySubmit} className="filter-form">
                        {allergies.map((allergy, index) => (
                            <div key={index} className="filter-checkbox">
                                <label>
                                    <input type="checkbox" value={allergy} onChange={handleAllergyCheck} />
                                    {allergy}
                                </label>
                            </div>
                        ))}
                        <button type="submit" className="apply-button">Exclude Selected</button>
                    </form>
                </div>
            </div>

        </div>
    );
}
