import React from "react";
import axios from "axios";
import NavBar from "./Nav";
import SearchBar from "./SearchBar";
import SearchFilters from "./SearchFilters";
import SearchResults from "./SearchResults";
import RecipeModal from "./RecipeModal";

import { useState, useEffect } from "react";

export default function BrowsePage({ userPlans, addToPlanFunction, apiURL, accessToken }) {
    const [recipeResults, updateRecipes] = useState([]);
    const [searchQuery, setQuery] = useState('');
    const [allergyExclusions, setAllergies] = useState('');
    const [type, setType] = useState('');
    const [offset, setOffset] = useState(0);
    const [displayNum, setDisplayNum] = useState(12);
    const [totalCount, setTotalCount] = useState(0);
    const [showModal, setModal] = useState(false);
    const [recipeInFocus, setRecipeInFocus] = useState({});


    async function getSearchResults() {

        const options = {
            params: {
                query: searchQuery,
                intolerances: allergyExclusions,
                type: type,
                number: 12,
                offset: offset,
                sort: 'popularity',
                access_token: accessToken
            },
            //withCredentials: true
        };
        try {
            const response = await axios.get(`${apiURL}/recipesearch`, options);
            //console.log(response.data);
            updateRecipes(response.data.results);
            setTotalCount(response.data.totalResults);

            if (response.data.totalResults < 12) {
                setDisplayNum(response.data.totalResults);
            } else {
                setDisplayNum(12)
            }
        } catch (error) {
            // Handle error
            console.error(error);
            console.log(error);
        }
    }

    useEffect(() => {
        getSearchResults();
    }, [offset,]);

    function nextPage(e) {
        e.preventDefault();
        if (offset + displayNum < totalCount) {
            setOffset(offset + displayNum);
        }
    }

    function prevPage(e) {
        e.preventDefault();
        if (offset - displayNum >= 0) {
            setOffset(offset - displayNum);
        }
    }


    function searchQueryChange(newQuery) {
        setQuery(newQuery);
    }

    function typeChange(newType) {

        setType(newType);
    }

    function allergyChange(newAllergies) {
        let allergies = '';
        newAllergies.forEach(element => {
            allergies += element + ',';
        });
        allergies = allergies.substring(0, allergies.length - 1);
        setAllergies(allergies);
    }

    async function focusRecipe(id) {
        console.log('Searching Spoonacular for Recipe: ' + id);
        setRecipeInFocus({ title: 'Loading Details ...' });
        setModal(true);
        setRecipeInFocus(await getRecipeDetails(id));
    }

    function blurRecipe() {
        setModal(false);
        setRecipeInFocus({});
    }

    async function getRecipeDetails(id) {
        const options = {
            params: {
                recipeID: id,
                access_token: accessToken
            },
            //withCredentials: true
        };
        try {
            const response = await axios.get(`${apiURL}/searchbyid`, options);
            console.log(response.data);
            return response.data;

        } catch (error) {
            // Handle error
            console.error(error);
            console.log(error);
        }
    }


    return (
        <div>
            <NavBar />
            <h1>Find Your Next Recipe</h1>
            <SearchBar queryChangeFunction={searchQueryChange} submitFunction={getSearchResults} apiURL={apiURL} accessToken={accessToken} />
            <section className="filters-results-container">
                <SearchFilters updateTypeFunction={typeChange} updateAllergyFunction={allergyChange} />
                <div className="results-body"><SearchResults results={recipeResults} focusRecipeFunction={focusRecipe} /></div>
            </section>
            {totalCount === 0 ? null : <div className="result-navigation">
                <button onClick={prevPage}>prev</button> Showing {offset}-{displayNum + offset} of {totalCount} <button onClick={nextPage}>next</button>
            </div>}

            {showModal ? <RecipeModal recipe={recipeInFocus} closeModalFunction={blurRecipe} userPlans={userPlans} addToPlan={addToPlanFunction} /> : null}


        </div>
    );

}