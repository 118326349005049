import React from "react";

export default function ErrorPage() {
  return (
    <div id="error-page">
      <h1>Oops! Something Went Wrong</h1>

    </div>
  );
}
