import React from "react";
import NavBar from "./Nav";
import PlanCard from "./PlanCard";
import { useState } from "react";

export default function MapsPage({ userData, changeUserDataFunction, deleteRecipe }) {

    const [tempData, setTempData] = useState(userData);
    const [createNew, setCreateNew] = useState(false);
    const [newName, setNewName] = useState('');
    const [nameUsed, setNameUsed] = useState(false);
    const [planFocus, setFocus] = useState(null);

    function handleCreateClick() {
        if (!createNew) {
            setCreateNew(true);
            setNameUsed(false);
        }
    }

    function handleFocus(plan) {
        setFocus(plan);
    }

    function handleBlur() {
        setFocus(null);
    }

    function checkName() {
        console.log("'" + newName + "'");
        for (const plan of tempData.plans) {
            console.log("'" + plan.title + "'");
            if (plan.title === newName) {
                return false;
            }
        }
        return true;
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (checkName()) {
            const newPlan = {
                title: newName,
                recipeCount: 0,
                image: "",
                recipes: [],
                ingredients: [],
            };

            setTempData(prevData => {
                const updatedData = {
                    ...prevData,
                    planCount: prevData.planCount + 1,
                    plans: [...prevData.plans, newPlan],
                };

                // Call the function with the updated data here
                changeUserDataFunction(updatedData);

                return updatedData;
            });

            setCreateNew(false);
            setNewName('');
        } else {
            setNameUsed(true);
        }
    }



    function handleChange(e) {
        e.preventDefault();
        setNewName(e.target.value);
    }

    function handleCancel() {
        setCreateNew(false);
        setNewName('');
    }

    function handleDelete() {
        // Check if planFocus is not null
        if (planFocus) {
            // Remove the plan from the array
            setTempData(prevData => {
                const updatedPlans = prevData.plans.filter(plan => plan.title !== planFocus.title);

                const updatedData = {
                    ...prevData,
                    planCount: updatedPlans.length,
                    plans: updatedPlans,
                };

                // Call the function with the updated data here
                changeUserDataFunction(updatedData);

                return updatedData;
            });
            // Remove the current focus after deletion
            setFocus(null);
        }
    }


    if (planFocus !== null) {
        return <PlanCard planIn={planFocus} handleBack={handleBlur} handleDelete={handleDelete} deleteRecipe={deleteRecipe} />
    }

    return (
        <div>
            <NavBar />
            <h1>Your Maps</h1>
            <div className="plans-grid">
                {tempData.plans.map((result, index) => (
                    <div
                        key={index}
                        className="plans-grid-item"
                        onClick={() => handleFocus(result)}
                    >
                        <img src={result.image} alt={result.title} />
                        <h2>{result.title}</h2>
                    </div>
                ))}
                <div
                    key={-1}
                    className="plans-grid-create-new"
                    onClick={handleCreateClick}
                >
                    {createNew ? <div>
                        <input type="text" className="title-input" onChange={handleChange}></input><button className="title-submit" onClick={handleSubmit}>+</button>
                        {nameUsed ? <p className="warning">Name already in use</p> : null}<button className="cancel-create" onClick={handleCancel}>Cancel</button></div> :
                        <h2>Create New Map</h2>}

                </div>
            </div>
        </div>


    )
}