import React, { useState } from 'react';
import NavBar from './Nav';
import PlanRecipeModal from './PlanRecipeModal';
export default function PlanCard({ planIn, handleBack, handleDelete, deleteRecipe }) {

    const { title, recipeCount, recipes, ingredients } = planIn;

    const [focusRecipe, setFocusRecipe] = useState(false);
    const [recipeInFocus, setRecipeInFocus] = useState({});

    const sortedIngredients = sortByAisle();

    function handleFocus(recipe) {
        setFocusRecipe(true);
        setRecipeInFocus(recipe);
    }

    function handleBlur() {
        setFocusRecipe(false);
        setRecipeInFocus({});
    }

    function sortByAisle() {
        return ingredients.reduce((acc, curr) => {
            // Split the aisle string into an array of aisle names
            const aisles = curr.aisle.split(";");

            // For each aisle the ingredient belongs to...
            aisles.forEach((aisle) => {
                // If the aisle does not exist in the accumulator, create it
                if (!acc[aisle]) {
                    acc[aisle] = [];
                }

                // Add the ingredient name to the appropriate aisle
                acc[aisle].push({ name: curr.name, uses: curr.uses });
            });

            return acc;
        }, {});
    }

    function capitalizeWords(str) {
        return str.toLowerCase().split(' ').map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
    }

    return (
        <div>
            <NavBar className='no-print' />
            <h1 className='no-print'>{title}</h1>
            <button className='no-print back-button' onClick={handleBack} >Back</button>
            <div className='plan-container-grid'>
                <div className='no-print'>
                    <h2>Recipes : {recipeCount}</h2>
                    <div className="recipes-plan-grid">
                        {recipes.map((recipe, index) => (
                            <div key={recipe.id} className="recipes-plan-grid-item" onClick={() => handleFocus(recipe)}>
                                <div className="recipe-content">
                                    <img src={recipe.image} alt={recipe.title} />
                                    <h2>{recipe.title}</h2>
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
                <div>
                    <h2 className='no-print'>Ingredients : {ingredients.length}</h2>

                    <div className="ingredients-list">
                        <button className='print-button no-print' onClick={() => window.print()}>Print Ingredients</button>
                        {Object.entries(sortedIngredients).map(([aisleName, ingredients]) => (
                            <div className="ingredient-list-item">
                                <h1>{aisleName}</h1>
                                <div>
                                    {ingredients.map((ingredient) => (
                                        <div>
                                            <h2>{capitalizeWords(ingredient.name)}</h2>
                                            {ingredient.uses.map((use) => (
                                                <p>{use.recipe} calls for {use.call}</p>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
            <button className='no-print red-button' onClick={handleDelete} >Delete Plan</button>
            {focusRecipe ? <PlanRecipeModal recipe={recipeInFocus} closeModalFunction={handleBlur} plan={planIn} removeRecipeFunction={deleteRecipe} /> : null}

        </div>
    );
};
