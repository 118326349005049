import React, { useState } from "react";

export default function PlanRecipeModal({ recipe, closeModalFunction, plan, removeRecipeFunction }) {

    const [moreInfo, setMoreInfo] = useState(true);

    function handleClose() {
        closeModalFunction();
    }

    function handleRemove() {
        closeModalFunction();
        removeRecipeFunction(plan, recipe);
    }



    function showMoreInfo() {
        setMoreInfo(!moreInfo);
    }

    //console.log(recipe);


    return (
        <div className="recipe-modal no-print" >
            <div className="recipe-modal-content">
                {recipe.title === 'Loading Details ...' ? <h1>Loading Recipe...</h1> : <div>
                    <div className="m-recipe-options">
                        <button onClick={handleClose}>Close</button>
                        <button onClick={showMoreInfo}>Show {moreInfo ? 'Less' : 'More'} Info</button>
                        <button className="red-button" onClick={handleRemove}>Remove From Map</button>

                    </div>

                    <h1>{recipe.title}</h1>
                    <p>Source : <a href={recipe.sourceUrl}>{recipe.sourceName}</a></p>
                    <img className="m-recipe-image" src={recipe.image} alt={recipe.title}></img>
                    <div className="m-recipe-summary" dangerouslySetInnerHTML={{ __html: recipe.summary }} />
                    {moreInfo ?
                        <div className='m-more-info-div'>
                            <h2>Ingredients :</h2>
                            <ol className="modal-ordered-list">{recipe.extendedIngredients.map((ingredient) => (
                                <li key={ingredient.id}>{ingredient.original}</li>
                            ))}
                            </ol>
                            <h2>Instructions :</h2>
                            <ol className="modal-ordered-list">{recipe.analyzedInstructions[0].steps.map((instruction) => (
                                <li>{instruction.step}</li>
                            ))}
                            </ol>
                        </div>
                        : null}

                </div>}

            </div>
        </div>
    )
}