import React, { useEffect } from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import HomePage from './components/HomePage';
import LandingPage from './components/LandingPage';
import AccountPage from './components/AccountPage';
import BrowsePage from './components/BrowsePage';
import MapsPage from './components/MapsPage';
import ErrorPage from './components/ErrorPage';


import axios from 'axios';
import './components/css/Styles.css';

//design your diet
function App() {

  const serverUrl = process.env.REACT_APP_PROD_URL;

  const [didLogout, setDidLogout] = useState(false);

  const [serverAuth, setServerAuth] = useState(false);

  const [userData, setUserData] = useState({});

  const [authToken, setAuthToken] = useState(null);

  const { user, isAuthenticated, isLoading, logout, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      const getAuthToken = async () => {
        const accessToken = await getAccessTokenSilently();
        setAuthToken(accessToken);
        //getServerAuth();
        //console.log(accessToken);
      }
      getAuthToken();
    }
  }, [getAccessTokenSilently, user?.sub])



  async function getServerAuth() {
    const options = {
      params: {
        user: user,
        access_token: authToken
      },
      //withCredentials: true
    };
    try {
      const response = await axios.get(`${serverUrl}/serverlogin`, options);
      console.log(response.data);
      setServerAuth(true);
      setUserData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }


  function handleLogout() {
    logout({ logoutParams: { returnTo: window.location.origin } })
      .then(() => getServerLogout())
      .catch((error) => {
        console.error(error);
      });
  }

  async function getServerLogout() {
    const options = {
      params: { user: user },
      withCredentials: true
    };
    try {
      const response = await axios.get(`${serverUrl}/serverlogout`, options);
      console.log(response);
      setServerAuth(false);
      setDidLogout(true); // Add this line
    } catch (error) {
      // Handle error
      console.error(error);
      console.log(error);
    }
  }

  async function updateDatabase(newData) {
    try {
      //console.log(user);
      const response = await axios.post(
        `${serverUrl}/backend_user_update`,
        {
          userID: user.sub,
          data: newData
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      /*, { withCredentials: true }*/
      console.log(response);
    } catch (error) {
      // Handle error
      console.error(error);
      console.log(error);
    }
  }



  function updateUserInfo(newData) {
    setUserData(newData);
    updateDatabase(newData);
  }

  function updateUserDataWithNewPlan(updatedPlan) {
    // Map over the plans
    const updatedPlans = userData.plans.map(plan => {
      // If this is the plan we want to update, return the updated plan
      if (plan.title === updatedPlan.title) {
        return updatedPlan;
      }
      // Otherwise, return the plan as-is
      return plan;
    });

    // Return a new object with the updated plans array
    return {
      ...userData,
      plans: updatedPlans,
    };
  }

  function removeIngredients(curIngredients, recipeIngredients, recipeName) {
    // Make a shallow copy to prevent direct mutation
    let updatedIngredients = [...curIngredients];

    for (const ingr of recipeIngredients) {
      const ingredientIndex = updatedIngredients.findIndex(i => i.id === ingr.id);
      if (ingredientIndex !== -1) {
        // If ingredient exists, find the use-case with the recipeName and remove it
        const useIndex = updatedIngredients[ingredientIndex].uses.findIndex(use => use.recipe === recipeName);
        if (useIndex !== -1) {
          updatedIngredients[ingredientIndex].uses.splice(useIndex, 1);
        }

        // If the uses array is empty, remove the ingredient itself
        if (updatedIngredients[ingredientIndex].uses.length === 0) {
          updatedIngredients.splice(ingredientIndex, 1);
        }
      }
    }

    return updatedIngredients;
  }


  function addIngredients(curIngredients, newIngredients, recipeName) {
    for (const ingr of newIngredients) {
      const exists = curIngredients.find(i => i.id === ingr.id);
      if (!exists) {
        curIngredients.push({
          id: ingr.id,
          image: ingr.image,
          aisle: ingr.aisle,
          name: ingr.name,
          uses: [{
            recipe: recipeName,
            call: ingr.original
          }]
        })
      } else {
        // Find the ingredient and add a new entry to its uses array
        const ingredient = curIngredients.find(i => i.id === ingr.id);
        ingredient.uses.push({
          recipe: recipeName,
          call: ingr.original
        });
      }
    }
    return curIngredients;
  }


  function addRecipeToPlan(plan, recipe) {
    // Check if the recipe already exists in plan.recipes
    if (plan.recipes && !plan.recipes.find(r => r.id === recipe.id)) {
      plan.recipes.push(recipe);
      plan.recipeCount++;
      plan.image = recipe.image;
      plan.ingredients = addIngredients(plan.ingredients, recipe.extendedIngredients, recipe.title);
      const newUserData = updateUserDataWithNewPlan(plan);
      updateUserInfo(newUserData);
      return true;
    }


    /*else if (!plan.recipes) {
      plan.recipes = [];
      plan.recipes.push(recipe);
      plan.image = recipe.image;
      plan.recipeCount = 1;
      const newUserData = updateUserDataWithNewPlan(plan);
      updateUserInfo(newUserData);
      return true;
    } */

    // Return false if the recipe was not added (i.e., it already exists)
    return false;
  }

  function removeRecipeFromPlan(plan, recipe) {
    // Check if the recipe exists in plan.recipes
    if (plan.recipes && plan.recipes.find(r => r.id === recipe.id)) {
      // Filter out the recipe to be removed
      plan.recipes = plan.recipes.filter(r => r.id !== recipe.id);
      plan.recipeCount--;
      plan.ingredients = removeIngredients(plan.ingredients, recipe.extendedIngredients, recipe.title);

      // If no more recipes, remove the image
      if (plan.recipes.length === 0) {
        plan.image = "";
      } else {
        plan.image = plan.recipes[0].image;
      }

      // Update the user data with the new plan and update it in the database
      const newUserData = updateUserDataWithNewPlan(plan);
      updateUserInfo(newUserData);

      return true;
    }

    // Return false if the recipe was not found in the plan
    return false;
  }


  useEffect(() => {
    if (!isLoading && isAuthenticated && !serverAuth && !didLogout && (authToken !== null)) {
      getServerAuth();
    } else if (!isLoading && !isAuthenticated) {
      setServerAuth(false);
    }
  }, [isLoading, isAuthenticated, serverAuth, didLogout, authToken]); // Also include didLogout as a dependency




  if (isLoading) {
    return <h1>Authorizing  ... </h1>
  }

  if (isAuthenticated) {
    //console.log(user);
    if (!serverAuth) {
      //console.log(user);
      return <h1>Loading User Data ...</h1>
    }
    return (

      <Router>
        <Routes>
          <Route path="/callback" element={<Navigate to="/home" />} />
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/account" element={<AccountPage logoutFunction={handleLogout} />} />
          <Route path="/browse" element={<BrowsePage userPlans={userData.plans} addToPlanFunction={addRecipeToPlan} apiURL={serverUrl} accessToken={authToken} />} />
          <Route path="/maps" element={<MapsPage userData={userData} changeUserDataFunction={updateUserInfo} deleteRecipe={removeRecipeFromPlan} />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>

    );
  } else {
    return <LandingPage />;
  }



  /*
  
  */

}

export default App;
