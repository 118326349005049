import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import './css/Landing.css';


function LandingPage() {
  const { loginWithRedirect } = useAuth0();

  return (
    <div>
      <h1>Welcome!</h1>
      <div className='post'>
        <button className='login-button' onClick={() => loginWithRedirect()}>Log In</button>
      </div>

      {/* More components or HTML here */}
    </div>
  );
}


export default LandingPage;