import React, { useState, useRef } from 'react';
import axios from 'axios';

export default function SearchBar({ submitFunction, queryChangeFunction, apiURL, accessToken }) {
    const [fillItems, setFill] = useState([]);
    const [showFill, setShowFill] = useState(false);
    const [searchVal, setVal] = useState('');
    const blurTimeout = useRef();


    const searchTextRef = useRef();  // add this line

    function handleChange(e) {
        const searchText = e.target.value;
        setVal(searchText);
        searchTextRef.current = searchText; // use ref here
        queryChangeFunction(searchText);
        applyAutoChanges(searchText);
    }

    async function applyAutoChanges(searchText) {
        if (searchText !== '') {
            setShowFill(true);
        } else {
            setShowFill(false);
        }

        try {
            const response = await axios.get(`${apiURL}/autocomplete`, {
                params: {
                    query: searchText,
                    number: 5,
                    access_token: accessToken
                },
                //withCredentials: true
            });
            const results = response.data;
            //console.log(results);
            //console.log(searchText + ' end -> curVal = ' + searchTextRef.current); // use ref here
            if (searchText === searchTextRef.current) {  // use ref here
                //console.log(searchText);
                setFill(results.data);

            }
        } catch (error) {
            console.error(error);
        }
    }


    function handleSubmit(e) {
        e.preventDefault();
        setFill([]);
        submitFunction(e);
    }

    function handleSelect(e) {
        e.stopPropagation();
        setShowFill(false);
        console.log(e.target.innerText);
        setVal(e.target.innerText);
        queryChangeFunction(e.target.innerText);
        setFill([]);
    }

    function handleBlur() {
        blurTimeout.current = setTimeout(() => setShowFill(false), 100);
    }

    function handleFocus() {
        clearTimeout(blurTimeout.current);
        setShowFill(true);
    }

    return (
        <form className="search-container" onSubmit={handleSubmit}>
            <div className="search-text-container">
                <input
                    type="text"
                    className="search-text"
                    onChange={handleChange}
                    value={searchVal}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                <div className={`autofill-container ${showFill ? 'visible' : 'hidden'}`}>
                    {fillItems.map((item) => (
                        <div
                            className="autofill-item"
                            key={item.id}
                            onClick={handleSelect}
                        >
                            {item.title}
                        </div>
                    ))}
                </div>
            </div>
            <input type="submit" className="search-submit" value={'Search'} />
        </form>
    );
}
