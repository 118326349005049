import './css/Nav.css';

import React from 'react';
import { Link } from 'react-router-dom';

function NavBar() {
  return (
    <ul className="navbar no-print">
      <li className="leftNav"><Link className='nav-link' to='/home'>Home</Link ></li>
      <li className="rightNav"><Link className='nav-link' to='/account'>Account</Link ></li>
      <li className="rightNav"><Link className='nav-link' to='/maps'>Maps</Link ></li>
      <li className="rightNav"><Link className='nav-link' to='/browse'>Find</Link ></li>
    </ul>
  );
}

export default NavBar;
